import * as styles from './style.css'
import iconUser from '~/assets/img/icon-user.svg'

type Props = {
  className?: string
  src?: string
  alt?: string
  size?: 'small' | 'medium' | 'large'
  tag?: keyof JSX.IntrinsicElements
}

export const UserIcon = ({ className, src, alt, size = 'medium', tag }: Props) => {
  const Tag = tag ?? 'figure'

  return (
    <Tag
      className={[styles.userIcon, styles[`userIcon__${size}`], !src && styles.userIcon__empty, className]
        .filter(Boolean)
        .join(' ')}
    >
      <img src={src || iconUser} alt={alt} className={styles.userIcon_image} />
    </Tag>
  )
}
